const Helper = {
    objectToArray(objectForArray)
    {
        // Trochu prasárna... ale nevím jak to udělat jinak
        var result = Object.keys(objectForArray).map((key) => [Number(key), objectForArray[key]]);
        $.each(result, function (id, row) {
            row.shift();
            $.each(row, function (idRow, item) {
                result[id] = item;
            });
        });
        return result;
    },
    getPercentColor(number)
    {
        let barva = '';
        if (number == 0)
        {
            barva = 'dark';
        }
        if (number > 0)
        {
            barva = 'danger';
        }
        if (number == 100)
        {
            barva = 'success';
        }
        return barva;
    },
    scrollToElement(id, timeOut = 0)
    {
        setTimeout(function() {
            var elmnt = document.getElementById(id);
            elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, timeOut);
    },
    removeDiacritic(input)
    {

        if ($.isNumeric(input) == true || input == '' || input === undefined)
        {
            return input;
        }
        else
        {
            let charBan = ['ě', 'š', 'č', 'ř', 'ž', 'ý', 'á', 'í', 'é', 'ď', 'ů', 'ú'];
            let charReplace = ['e', 's', 'c', 'r', 'z', 'y', 'a', 'i', 'e', 'd', 'u', 'u'];
            let output = input;
            $.each(charBan, function (key, char) {
                output = output.replaceAll(char, charReplace[key]);
            });
            return output;
        }
    },
    prepareForSearch(input)
    {
        if ($.isNumeric(input) || input == '' || input === undefined)
        {
            return input;
        }
        else
        {
            let output = input;
            output = output.toLowerCase().trim();
            return output;
        }
    },
    autoId(pocetZnaku)
    {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < pocetZnaku; i++ )
        {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    maxLength(value, max = 35, addPoints = true)
    {
        let editValue = value;
        if (editValue.length > max)
        {
            editValue = editValue.substring(0, max);
            if (addPoints == true)
            {
                editValue += '..';
            }
        }
        return editValue;
    },
    formatNumber(value) {

        let newValue = value;
        if(isNaN(newValue))
        {
            return newValue;
        }
        newValue = Helper.emptyNumber(newValue);
        newValue = Helper.decimalNumber(newValue);
        newValue = newValue.toLocaleString("cs-CZ");

        return newValue;

    },
    decimalNumber(value) {
        let newValue = value;
        newValue = Helper.emptyNumber(newValue);
        if (newValue % 1 != 0)
        {
            newValue = Math.floor(newValue * 100) / 100;
        }
        return newValue;
    },
    emptyNumber(value)
    {
        let newValue = value;
        if (newValue == '' || newValue == null || newValue == undefined)
        {
            newValue = 0;
        }
        return newValue;
    },
    detailPolozky(value, idPolozky, max = 0)
    {
        if (max > 0)
        {
            value = Helper.maxLength(value, max);
        }
        let detail = '<a href="/detail/pol-nd/' + idPolozky + '/0" class="router-link-active router-link-detail"> ' + value + ' </a>'
        return detail;
    },
    onlyNumber(value)
    {
        let newValue = value;
        if (/\D/g.test(newValue))
        {
            newValue = newValue.replace(/\D/g, '');
        }
        return newValue;
    }



}

export default Helper
