export function localConfig()
{
    let userDeveloper = false
    let baseUrl = window.location.href
    let appUrl = ''
    let apiUrl = ''
    let port = 8080;

    if (baseUrl.indexOf("localhost") > 0 || baseUrl.indexOf("192.168.") > 0)
    {
        userDeveloper = true
        let appUrlArray = baseUrl.split(':' + port)
        let appUrlDomain = appUrlArray[0];
        appUrl = appUrlDomain + ':' + port;
        if (appUrlDomain.indexOf('rest.') <= 0)
        {
            appUrlDomain = appUrlDomain.replace('http://', 'http://rest.');
        }
        apiUrl = appUrlDomain + ':8081/v2/KmMQgeBP/sklady/'
    }
    else
    {
        apiUrl = 'https://rest.vzv.cz/v2/KmMQgeBP/sklady/'
        appUrl = 'https://sklady.vzv.cz/'
    }

    let returnArray = {
        user_developer: userDeveloper,
        base_url: baseUrl,
        app_url: appUrl,
        api_url: apiUrl,
    }
    return returnArray
}

export function getIDEshopu()
{
    return 1;
}