import axiosApiPostV2 from "./axiosApiMethod";
import {busAlert} from "../main";
import router from "./index";
export function inventuryBarcode(barcode, url)
{
    var barcodeInventury = barcode;
    var urlInventury = url;
    getInventuryPage();

    function getInventuryPage()
    {
        let delkaUrl = urlInventury.length;

        // detail inventury - známe idInventury
        if (delkaUrl >= 3)
        {
            let idInventury = 0;
            let idRegalu = 0;
            let idPoziceRegalu = 0;

            if (urlInventury[2] > 0)
            {
                idInventury = urlInventury[2];
            }

            if (idInventury > 0)
            {

                if (delkaUrl >= 5)
                {
                    idRegalu = urlInventury[4];
                    // Je vybraná parkovací pozice
                    if (delkaUrl >= 7)
                    {
                        idPoziceRegalu = parseInt(urlInventury[6]);
                    }

                    // Jsme v detailu položky
                    if (delkaUrl == 9)
                    {

                    }
                }

                let akcniCislo = barcodeInventury.akcni_cislo;
                if (akcniCislo == 2)
                {
                    let paramsPolozka = {
                        id_inventury: idInventury,
                        skladove_cislo: barcodeInventury.skladove_cislo,
                        cislo_polozky: barcodeInventury.cislo_polozky,
                    };

                    if (delkaUrl != 7)
                    {
                        if (delkaUrl < 7)
                        {
                            if (barcodeInventury.cislo_polozky > 0)
                            {
                                axiosApiPostV2('inventury-detail-inventury-regaly-parkovaci-pozice-polozky-detail-polozky-datamatrix', paramsPolozka, 'polozky').then( result => {
                                    if (result.id_inventury_polozky > 0)
                                    {
                                        presmerujNaParkovaciPozici(idInventury, result.id_regalu, result.id_pozice_regalu, result.kod_pozice);
                                    }
                                    else
                                    {
                                        alertInventury(1);
                                    }
                                });
                            }
                            else
                            {
                                alertInventury(2);
                            }
                        }
                        else
                        {
                            alertInventury(8);
                        }
                    }
                    else
                    {
                        // Jsme v přehledu parkovací pozice - načítáme jednotlivé položky
                        if (barcodeInventury.cislo_polozky > 0)
                        {
                            // Položkovatelný ND
                            axiosApiPostV2('inventury-detail-inventury-regaly-parkovaci-pozice-polozky-detail-polozky-datamatrix', paramsPolozka, 'polozky').then( result => {
                                if (result.id_inventury_polozky > 0)
                                {
                                    if (idPoziceRegalu == result.id_pozice_regalu)
                                    {
                                        let paramsZmenaHodnoty = {
                                            id_inventury_polozky: result.id_inventury_polozky,
                                            mnozstvi_nove_nalezeno: result.mnozstvi_nove_skladem,
                                            mnozstvi_pouzite_nalezeno: result.mnozstvi_pouzite_skladem
                                        };

                                        let format = result.mnozstvi_nove_skladem + ',00';
                                        if (result.mnozstvi_pouzite_skladem > 0)
                                        {
                                            format += ' / ' + result.mnozstvi_pouzite_skladem + ',00';
                                        }

                                        axiosApiPostV2('inventury-detail-inventury-regaly-parkovaci-pozice-polozky-detail-polozky-zmena-hodnoty', paramsZmenaHodnoty, 'data').then( result => {
                                            $('#ipp-' + paramsZmenaHodnoty.id_inventury_polozky).html(format);
                                            $('#ipp-row-' + paramsZmenaHodnoty.id_inventury_polozky).addClass('text-light bg-success');
                                            Helper.scrollToElement('ipp-row-' + paramsZmenaHodnoty.id_inventury_polozky);
                                            alertInventury(4);
                                        });
                                    }
                                    else
                                    {
                                        console.log(result);
                                        // TODO
                                        presmerujNaDetailPolozkyNaJinePozice(idInventury, idRegalu, idPoziceRegalu, result.id_inventury_polozky);
                                    }
                                }
                                else
                                {
                                    alertInventury(1);
                                }
                            });
                        }
                        else
                        {
                            // nepol v detailu
                            let paramsPolozkaNepol = {
                                id_inventury: idInventury,
                                skladove_cislo: barcodeInventury.skladove_cislo,
                                cislo_polozky: barcodeInventury.cislo_polozky,
                                id_parkovaci_pozice: idPoziceRegalu,
                                nepolozkovatelna: true
                            };

                            axiosApiPostV2('inventury-detail-inventury-regaly-parkovaci-pozice-polozky-detail-polozky-datamatrix', paramsPolozkaNepol, 'polozky').then( result => {
                                if (result.id_inventury_polozky > 0)
                                {
                                    if (result.id_pozice_regalu == idPoziceRegalu)
                                    {
                                        presmerujNaDetailPolozkyNaJinePozice(idInventury, idRegalu, idPoziceRegalu, result.id_inventury_polozky, 6);
                                    }
                                    else
                                    {
                                        alertInventury(7);
                                    }
                                }
                                else
                                {
                                    alertInventury(7);
                                }
                            });
                        }
                    }
                }

                // Jedná se o parkovací pozici
                if (akcniCislo == 1)
                {
                    if (delkaUrl >= 7)
                    {
                        // jedná se o detail položky - budeme přeparkovávat
                    }
                    else
                    {
                        // jedná se o rychlý výběr pozice -> přesměrujeme
                        axiosApiPostV2('detail-parkovaci-pozice', {'id_pozice_regalu': barcodeInventury.id_parkovaci_pozice}, 'detail').then( detailPozice => {
                            presmerujNaParkovaciPozici(idInventury, detailPozice.id_regalu, detailPozice.id_pozice_regalu, detailPozice.nazev_regalu + ' - ' + detailPozice.kod_pozice);
                        });
                    }
                }
            }
        }
    }

    function presmerujNaParkovaciPozici(idInventury, idRegalu, idPoziceRegalu, kodPozice)
    {
        let newUrl = '/inventury/detail/'+ idInventury +'/regal/'+ idRegalu +'/parkovaci-pozice/' + idPoziceRegalu;
        alertInventury(3, kodPozice);
        router.push(newUrl);
        if (urlInventury.indexOf('parkovaci-pozice/' + idPoziceRegalu))
        {
            //router.go();
        }
    }

    function presmerujNaDetailPolozkyNaJinePozice(idInventury, idRegalu, idPoziceRegalu, idInventuryPolozky, typAlertu = 5)
    {
        let newUrl = '/inventury/detail/'+ idInventury +'/regal/'+ idRegalu +'/parkovaci-pozice/' + idPoziceRegalu + '/polozka/' + idInventuryPolozky;
        alertInventury(typAlertu);
        router.push(newUrl);
    }

    function alertInventury(typAlertu, dynText = '')
    {
        switch (typAlertu)
        {
            // Nenalezená položka
            case 1:
                busAlert.$emit('alertMessage', {text: 'Tato položka se nenachází v inventuře - ověřte zda byla správně přijata/vydata, popřípadě kontaktujte IT oddělení!', color: 'danger', autoHide: 0, strong: 'Nelze provést inventuru!'});
                $.playSound(require('@/assets/sounds/cancel.mp3'));
                break;
            // Nepoložkovatelný ND
            case 2:
                busAlert.$emit('alertMessage', {text: 'Nelze zobrazit detail položky v inventuře protože se jedná o nepoložkovatelný ND!', color: 'danger', autoHide: 0, strong: 'Nelze provést inventuru!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
            // Přesměrování na parkovací pozici
            case 3:
                busAlert.$emit('alertMessage', {text: 'Otevřel se detail parkovací pozice '+ dynText +'!', color: 'info', autoHide: 5, strong: 'Přesměrování na detail!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
            // Provedená inventura
            case 4:
                busAlert.$emit('alertMessage', {text: 'Položka byla zinventarizována!', color: 'success', autoHide: 3, strong: 'Provedli jste inventuru!'});
                break;
            case 5:
                busAlert.$emit('alertMessage', {text: 'Otevřel se detail položky, který se nachází na jiné parkovací pozici! Položku můžete přeparkovat nebo ji zaskladněte na její pravé místo.', color: 'info', autoHide: 5, strong: 'Přesměrování na detail!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
            // Nepoložkovatelný ND z detailu parkovací pozice
            case 6:
                busAlert.$emit('alertMessage', {text: 'Byly jste přesměrováni na detail nepoložkovatelného ND!', color: 'info', autoHide: 3, strong: 'Nelze provést inventuru!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
            // Nepoložkovatelný ND na jiné parkovací pozici
            case 7:
                busAlert.$emit('alertMessage', {text: 'Nelze zobrazit detail položky v inventuře, protože se jedná o nepoložkovatelný ND který je na jiný pozici než je aktuálně vybraná!', color: 'danger', autoHide: 0, strong: 'Nelze provést inventuru!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
            case 8:
                busAlert.$emit('alertMessage', {text: 'Na tomto místě není možné načítat jinou položku! Vraťte se zpět na parkovací pozici.', color: 'danger', autoHide: 0, strong: 'Neznámá operace!'});
                $.playSound(require('@/assets/sounds/info_alert.mp3'));
                break;
        }
    }


}