import router from "@/router/index";
import axiosApiPostV2 from "@/router/axiosApiMethod";
import Vue from "vue";

export function routerDetail(detail, url)
{
    let page = '';
    let dataPolozka = {};
    switch (detail.typ)
    {
        case "detail_pol_nd":
            routeDetailPolozky(detail, url);
            break;
    }

    if (page != '')
    {
        routePage(page, url);
    }

    function routeDetailPolozky(barcode, url)
    {
        routePage('/detail/pol-nd-v2/' + barcode.id_pol_nd_polozky + '/' + barcode.skladove_cislo + '/' + barcode.cislo_polozky, url);
    }

    function routePage(page, url)
    {
        router.push(page);
        if (url[0] == 'detail' && url[1] == 'pol-nd')
        {
            router.go();
        }
    }

}